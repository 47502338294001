import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
//import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import MenuIcon from "@material-ui/icons/Menu";

import Menu from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/styles";
import MenuItem from "@material-ui/core/MenuItem";
import { push } from "connected-react-router";
import { userSignOut, userSignOutSuccess } from "actions/Auth";
//import useMediaQuery from "@material-ui/core/useMediaQuery";

const styles = () => ({
  fullName: {
    margin: "auto 0 auto 8px",
    maxWidth: "150px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

//function TransferGuideLink() {
//  const matches = useMediaQuery("(min-width:600px)");
//  if (matches) {
//    return <Link to="/transfer-guide">Hướng dẫn chuyển đổi Zing ID</Link>;
//  }
//  return "";
//}

class Header extends React.Component {
  constructor() {
    super();

    this.state = {
      anchorEl: null,
    };
  }

  componentDidUpdate() {
    const { logoutId, gameID, push } = this.props;
    if (logoutId === gameID || logoutId === "all") {
      push("/");
    }
  }

  componentWillUnmount() {
    const { userSignOutSuccess, logoutId } = this.props;
    if (logoutId !== null) {
      userSignOutSuccess(null);
    }
  }

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleSignout = (type) => () => {
    const { gameID, userID, userSignOut } = this.props;
    userSignOut({ gameID, userID, type });
  };

  render() {
    const { anchorEl } = this.state;
    const { logoURL, userData, classes, hideUserInfo, pathname } = this.props;
    const { data } = userData;
    return (
      <div className="header">
        <Link to="/home">
          <img
            src={process.env.PUBLIC_URL + "/Zing_official_logo.png"}
            alt=""
          />
        </Link>
        <div>
          {!hideUserInfo ? (
            <div
              className="info-acc"
              style={{ display: "flex", alignItems: "center", gap: "5px" }}
            >
              <Link
                to="/transfer-guide"
                className="guide-link-desktop transfer-link"
              >
                Hướng dẫn chuyển đổi Zing ID
              </Link>
              <img style={{ marginRight: "5px" }} src={logoURL} alt="" />
              <div style={{ margin: "auto 0" }}>Xin chào</div>
              <Tooltip
                title={data && data.fullName ? data.fullName : "Anonymous"}
              >
                <b className={`${classes.fullName} header-name-clip`}>
                  {data && data.fullName ? data.fullName : "Anonymous"}
                </b>
              </Tooltip>
              <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                style={{ padding: 0, marginLeft: "5px" }}
                onClick={(event) => this.handleClick(event)}
              >
                <MenuIcon fontSize="small" />
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                className="user-menu"
                keepMounted
                open={Boolean(anchorEl)}
                onClose={(event) => this.handleClose(event)}
              >
                <MenuItem onClick={this.handleSignout("one")}>
                  Đăng xuất
                </MenuItem>
                <MenuItem onClick={this.handleSignout("all")}>
                  Đăng xuất tất cả
                </MenuItem>
                <MenuItem
                  to="/transfer-guide"
                  className="transfer-link-menu"
                  component={Link}
                >
                  Hướng dẫn chuyển đổi Zing ID
                </MenuItem>
              </Menu>
            </div>
          ) : (
            ""
          )}
          {pathname === "/" ? (
            <Link to="/transfer-guide" className="transfer-link">
              Hướng dẫn chuyển đổi Zing ID
            </Link>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, router }) => {
  const { userData, logoutId } = auth;
  const {
    location: { pathname },
  } = router;
  return { userData, logoutId, pathname };
};

export default connect(mapStateToProps, {
  userSignOut,
  push,
  userSignOutSuccess,
})(withStyles(styles)(Header));
